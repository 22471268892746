import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import React from "react";

import { useAuthStore } from "src/context/authStore";
import { useTagsById } from "src/hooks/useTags";
import type { EntityTag, EntityTagCount } from "src/services/models/EntityTag";
import type { TagEntityType } from "src/services/models/Tag";
import { useEntityTags, useTradespersonByPartnerTradesperson } from "src/services/queries";
import { shouldForwardProp } from "src/utils/shouldForwardProp";

type EntityTagItemProps = {
  tradespersonId?: string;
  companyId?: string;
  source?: TagEntityType.Job;
  entityTagCount: EntityTagCount;
  onCountClicked: (tagId: string) => void;
};

export const EntityTagItem = ({
  entityTagCount,
  onCountClicked,
  source,
  tradespersonId,
  companyId,
}: EntityTagItemProps) => {
  const { tagsById } = useTagsById();

  const entityTagsQuery = useEntityTags({
    variables: {
      tradespersonId,
      companyId,
      source,
      tagId: entityTagCount.tagId,
    },
    skip: true,
  });

  const tag = tagsById[entityTagCount.tagId];
  const shouldShowReviewToolTip = Boolean(tradespersonId && source);
  const entityTags = entityTagsQuery.data;

  if (!tag) {
    return null;
  }
  return (
    <div>
      <Chip
        label={
          <Box>
            <Typography data-testid="entity-tag-item-label" variant="button">
              # {tag.label}
            </Typography>
            <Tooltip
              onOpen={() => {
                if (shouldShowReviewToolTip && !entityTags) {
                  entityTagsQuery.refetch();
                }
              }}
              componentsProps={{
                tooltip: {
                  sx: {
                    display: shouldShowReviewToolTip ? "block" : "none",
                    backgroundColor: "white",
                    boxShadow: "0px 2px 5px #C7CDDB",
                    border: "1px solid whitesmoke",
                    color: "#292B2E",
                  },
                },
              }}
              title={
                entityTagsQuery.isLoading ? (
                  <CircularProgress size={20} sx={{ marginTop: "5px" }} />
                ) : (
                  <Stack>
                    {entityTags?.map((entityTag, i) => {
                      return (
                        <Box key={`${entityTag.id}-from-job`} display="flex" alignItems="center">
                          <Typography
                            data-testid="entity-tag-item-label"
                            variant="body1"
                            ml="auto"
                            mr="5px"
                          >
                            {i + 1}. from Job
                          </Typography>
                          <Link
                            href={{ pathname: "/jobs/[id]", query: { id: entityTag.jobRefId } }}
                            passHref
                            legacyBehavior
                          >
                            <Typography variant="button" sx={{ cursor: "pointer" }}>
                              {entityTag.jobRefId?.toUpperCase()}
                            </Typography>
                          </Link>
                        </Box>
                      );
                    })}
                  </Stack>
                )
              }
            >
              <NumberChipStyled
                data-testid="entity-tag-item-count"
                $marginLeft={5}
                onClick={() => {
                  onCountClicked(entityTagCount.tagId);
                }}
              >
                {entityTagCount.count}
              </NumberChipStyled>
            </Tooltip>
          </Box>
        }
        sx={{
          marginBottom: "10px",
          backgroundColor: "#E8EDF9",
        }}
        deleteIcon={
          <IconButton data-testid="delete-entity-tag">
            <CloseIcon sx={{ fontSize: "14px", color: grey["600"] }} />
          </IconButton>
        }
        onDelete={() => {
          onCountClicked(entityTagCount.tagId);
        }}
      />
    </div>
  );
};

type ReviewEntityTagProps = {
  entityTag: EntityTag;
  deleteEntityTag: (entityTagId: string) => void;
  showFromJobLabel?: boolean;
  showTradespersonInfo?: boolean;
};

export const ReviewEntityTag = ({
  entityTag,
  deleteEntityTag,
  showFromJobLabel,
  showTradespersonInfo,
}: ReviewEntityTagProps) => {
  const partnerId = useAuthStore((state) => state.partnerId);

  const tradespersonByPartnerTradesperson = useTradespersonByPartnerTradesperson({
    variables: {
      partnerId: partnerId ?? "none",
      tradespersonId: entityTag.jobAssignedTpId ?? "none",
    },
    skip: !entityTag.jobAssignedTpId || !partnerId,
  });

  const { tagsById } = useTagsById();

  const tag = tagsById[entityTag.tagId];

  if (!tag) {
    return null;
  }

  const tpp = tradespersonByPartnerTradesperson.data;

  return (
    <ChipStyled>
      <Stack direction="row" alignItems="center" flex={1} mr="5px">
        <Typography data-testid="entity-tag-item-label" variant="button">
          # {tag.label}
        </Typography>
        {entityTag.jobRefId && showFromJobLabel && (
          <>
            <Typography data-testid="entity-tag-item-label" variant="body1" ml="auto" mr="5px">
              from Job
            </Typography>
            <Link
              href={{ pathname: "/jobs/[id]", query: { id: entityTag.jobRefId } }}
              passHref
              legacyBehavior
            >
              <Typography variant="button" sx={{ cursor: "pointer" }}>
                {entityTag.jobRefId.toUpperCase()}
              </Typography>
            </Link>
          </>
        )}

        {showTradespersonInfo && tpp && (
          <Typography ml="auto" variant="body1">
            from <strong>{tpp?.tradesperson?.companyName}, </strong>
            {tpp?.tradesperson?.fullName}
          </Typography>
        )}
      </Stack>
      <IconButton
        data-testid="review-entity-delete-tag"
        onClick={() => deleteEntityTag(entityTag.id)}
      >
        <CloseIcon sx={{ fontSize: "14px", color: grey["600"] }} />
      </IconButton>
    </ChipStyled>
  );
};

const NumberChipStyled = styled("div", { shouldForwardProp: shouldForwardProp })<{
  $marginLeft?: number;
}>`
  border-radius: 100%;
  background-color: white;
  padding: 5px;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  color: #292b2e;
  height: 20px;
  min-width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => props.$marginLeft ?? 0}px;
  cursor: pointer;
`;

const ChipStyled = styled("div")`
  height: 32px;
  display: flex;
  background-color: #e8edf9;
  border-radius: 100px;
  padding-left: 12px;
  padding-right: 5px;
`;
